/// <reference path="../definitions/QueryTs.d.ts" />
/// <reference path="../definitions/QueryTs.Date.d.ts" />

type QueryTemplateTypes = 'state' | 'array' | 'price' | 
'datetime' | 'datetimeshort' | 'date' | 'date-format' | 'time' |
'preview' | 'get' | 'color' | 'score' | 'input-value' | 'salesUnit';

type SalesUnits = 'Trolley' | 'Layer' | 'Quantity' | 'Bunch' | 'Piece'

interface ISalesUnitQuantityInfo {
    code: string;
    quantity: number;
    quantityUnit: number;
    unit: SalesUnits;
}

interface IQueryTemplateRenderer
{
    render(value: any, path: string, args: any): string;
}

class QueryTemplateRendererState
    implements IQueryTemplateRenderer {
    render(value: any, path: string, args: any): string {
        let names = '';
        if (args) {
            let splitted = value.split(/(?=[A-Z])/);
            let splittedText = args[0].split("|");
            for (let x = 0; x < splittedText.length; x++) {
                names += '<span class="state-color-' + splitted[x >= splitted.length ? 0 : x] + '">' + splittedText[x] + '</span>';
            }
        }
        return "<span class='state-item state-color-" + value + "'>"+ names + "</span>";
    }
}
class QueryTemplateRendererSalesUnit
    implements IQueryTemplateRenderer {
    render(value: any, path: string, args: any): string {
        let arr = value as ISalesUnitQuantityInfo[];
        return arr.filter(a => a.quantity != 0)
            .map(a => `${a.quantity}x${a.quantityUnit}`)
            .join(', ');
    }
}

class QueryTemplateRendererSalesUnitAlternative
    implements IQueryTemplateRenderer {
    render(value: any, path: string, args: any): string {

        let str : string[] = [];

        let arr = value as ISalesUnitQuantityInfo[];
        let bunch = arr.find(a => a.unit =="Bunch");
        for(var item of arr.filter(a => a.quantity != 0))
        {
            if (item.unit == "Quantity" && bunch != null && bunch.quantityUnit != 0)
            {
                str.push(`${item.quantity}x${item.quantityUnit/bunch.quantityUnit}x${bunch.quantityUnit}`);
            }
            else
            {
                str.push(`${item.quantity}x${item.quantityUnit}`);
            }
        }
        return str.join(", ");
    }
}
class QueryTemplateRendererArray
    implements IQueryTemplateRenderer {
    render(value: any, path: string, args: any): string {
        return value.join(args[0] ? args[0] : ', ');
    }
}
class QueryTemplateRendererPrice
    implements IQueryTemplateRenderer {
    render(value: any, path: string, args: any): string {
        return value.toFixed(args[0] ? args[0] : 4);
    }
}
class QueryTemplateRendererDateTime
    implements IQueryTemplateRenderer {
    render(value: any, path: string, args: any): string {
        return new QueryDate(value).format("dd-mm-yyyy HH:MM:ss", false);
    }
}
class QueryTemplateRendererDateTimeShort
    implements IQueryTemplateRenderer {
    render(value: any, path: string, args: any): string {
        return new QueryDate(value).format("dd-mm HH:MM", false);
    }
}
class QueryTemplateRendererDate
    implements IQueryTemplateRenderer {
    render(value: any, path: string, args: any): string {
        return new QueryDate(value).format("dd-mm-yyyy", true);
    }
}
class QueryTemplateRendererDateFormat
    implements IQueryTemplateRenderer {
    render(value: any, path: string, args: any): string {
        return new QueryDate(value).format(args[0], false);
    }
}
class QueryTemplateRendererTime
    implements IQueryTemplateRenderer {
    render(value: any, path: string, args: any): string {
        return new QueryDate(value).format("HH:MM:ss", false);
    }
}
class QueryTemplateRendererPreview
    implements IQueryTemplateRenderer {
    render(value: any, path: string, args: any): string {
        switch(args[0]) {
            case "48":
                break;
            default:
                value = value.replace('=48x48/', `=${args[0]}x${args[0]}/`)
                break;
        }
        return "<img src='" + value + "' />";
    }
}
class QueryTemplateRendererGet
    implements IQueryTemplateRenderer {
    render(value: any, path: string, args: any): string {
        let innerValue = value[args[0]];
        if (innerValue || innerValue === 0 || innerValue === false) {
            var renderer = QueryTemplateRenderers.get(args[1]);
            if (renderer != null) {
                return renderer.render(value[args[0]], path, args.slice(2));
            } else {
                return value[args[0]];
            }
        }

        return "";
    }
}
class QueryTemplateRendererColor
    implements IQueryTemplateRenderer {
    render(value: any, path: string, args: any): string {
        value = [].concat(value);
        let toReturn = '<span class="color-item">';
        for (let x = 0; x < value.length; x++) {
            if (value[x])
                toReturn += "<span style='background-color: " + value[x]+"'></span>";
        }
        toReturn += "</span>";
        return toReturn;
    }
}
class QueryTemplateRendererScore
    implements IQueryTemplateRenderer {
    render(value: any, path: string, args: any): string {
        let tresholds = args[0].split(",");
        let color = "Green";
        if (value && value <= tresholds[0]) {
            color = "Red";
        } else if (value <= tresholds[1]) {
            color = "Orange";
        }

        return "<span class='score-item state-color-" + color + "'></span>";
    }
}
class QueryTemplateRendererInputValue
    implements IQueryTemplateRenderer {
    render(value: any, path: string, args: any): string {
        return "<input class='input-value' value='"+value+"' type='hidden' />";
    }
}
class QueryTemplateRenderers {
    private static _renderers: {[key:string]: IQueryTemplateRenderer} = 
    {
        'state': new QueryTemplateRendererState(),
        'salesUnit': new QueryTemplateRendererSalesUnit(),
        'array': new QueryTemplateRendererArray(),
        'price': new QueryTemplateRendererPrice(),
        'datetime': new QueryTemplateRendererDateTime(),
        'datetimeshort': new QueryTemplateRendererDateTimeShort(),
        'date': new QueryTemplateRendererDate(),
        'date-format': new QueryTemplateRendererDateFormat(),
        'time': new QueryTemplateRendererTime(),
        'preview': new QueryTemplateRendererPreview(),
        'get': new QueryTemplateRendererGet(),
        'color': new QueryTemplateRendererColor(),
        'score': new QueryTemplateRendererScore(),
        'input-value': new QueryTemplateRendererInputValue()
    }
    
    static get(type: QueryTemplateTypes) : IQueryTemplateRenderer | null {
        if (this._renderers.hasOwnProperty(type)) {
            return this._renderers[type];
        }

        return null;
    }
    static appendOrOverwrite(type: QueryTemplateTypes, sort: IQueryTemplateRenderer)
    {
        this._renderers[type] = sort;
    }
}
class QueryTemplate {

    private readonly _renderer: Function;

    constructor(template: string) {
        let matcher = new RegExp(/\{(.+?)\}|$/g, "g");

        let escapes: any = {
            "'": "'",
            '\\': '\\',
            '\r': 'r',
            '\n': 'n',
            '\u2028': 'u2028',
            '\u2029': 'u2029'
        };
        let escapeRegExp = /\\|'|\r|\n|\u2028|\u2029/g;
        let escapeChar = function (match: string) {
            return '\\' + escapes[match];
        };

        // Compile the template source, escaping string literals appropriately.
        let index = 0;
        let source = "var __p='";
        template.replace(matcher, function (match, escape, offset) {
            source += template.slice(index, offset).replace(escapeRegExp, escapeChar);
            index = offset + match.length;

            if (escape) {
                let found = escape.split(":");
                source += "'+\n(__f.call(this, obj." + QueryJSON.toCamelCase(found[0]) + ", '" + found[0] + "', '" + found[1] + "', ['" + found.slice(2).join("','") + "']))+\n'";
            }

            return match;
        });
        source += "';\nreturn __p;\n";

        try {
            this._renderer = new Function('obj', '__f', source);
        } catch (e: any) {
            e.source = source;
            throw e;
        }
    }
    render(data: any): string {
        return this._renderer.call(this, data, this.format);
    }
    format (value: any, path: string, type: QueryTemplateTypes, args: any): any {
        if (value || value === 0 || value === false) {
            let renderer = QueryTemplateRenderers.get(type);
            if (renderer != null) {
                return renderer.render(value, path, args);
            }
            else {
                return value;
            }
        }
        switch (type) {
            default:
                return '';
        }
    }
}